import React, { ComponentType, useMemo } from 'react';
import fakeOsLogo from '../assets/img/logo/fakeos-logo-no-border.png';
import fakeOsLogoOnlyTextWhite from '../assets/img/logo/fakeos-logo-only-text-white.png';
import fakeOsLogoOnlyText from '../assets/img/logo/fakeos-logo-only-text.png';
interface Props {
  altDescription?: string;
  asH1?: boolean;
  textOnly?: boolean;
  white?: boolean;
}
export const Logo: ComponentType<Props> = props => {
  const { white, asH1, altDescription = 'fakeOS', textOnly = false } = props;
  const image = useMemo(() => {
    if (white) {
      return fakeOsLogoOnlyTextWhite;
    }
    if (textOnly) {
      return fakeOsLogoOnlyText;
    }
    return fakeOsLogo;
  }, [textOnly, white]);
  if (asH1) {
    return (
      <h1>
        <img className="logo" alt={altDescription} src={image} />
      </h1>
    );
  }
  return <img className="logo" alt={altDescription} src={image} />;
};
