import classNames from 'classnames';
import { Link } from 'gatsby';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAuth } from '../auth';
import { getWindowSize } from '../utils';
import { Logo } from './logo';
interface Props {
  fixed?: boolean;
}

export const Navigation: ComponentType<Props> = props => {
  const { user } = useAuth();
  const { fixed: initiallyFixed } = props;
  const placeholderRef = useRef<HTMLElement>();
  const [collapsed, setCollapsed] = useState(true);
  const [fixed, setFixed] = useState(initiallyFixed);
  const toggleMenu = useCallback(() => {
    setCollapsed(c => !c);
  }, []);

  const handleScroll = useCallback(() => {
    if (initiallyFixed) {
      return;
    }
    const windowHeight = getWindowSize()[1];
    const scrollBarHeight = document.getElementById('navigation-bar')
      .clientHeight;

    if (window.scrollY > windowHeight - scrollBarHeight) {
      setFixed(true);
      placeholderRef.current.style.height = `${scrollBarHeight}px`;
      placeholderRef.current.style.display = 'block';
    } else {
      setFixed(false);
      placeholderRef.current.style.display = 'none';
    }
  }, [initiallyFixed]);

  useEffect(() => {
    placeholderRef.current = document.getElementById('navigation-placeholder');
    window.addEventListener('scroll', handleScroll);
    if (initiallyFixed) {
      const scrollBarHeight = document.getElementById('navigation-bar')
        .clientHeight;
      placeholderRef.current.style.height = `${scrollBarHeight}px`;
      placeholderRef.current.style.display = 'block';
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div id="navigation-placeholder"></div>
      <nav
        id="navigation-bar"
        className={classNames(
          'navbar is-dark',
          fixed ? 'is-fixed-top' : undefined
        )}
      >
        <a
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>

        <div
          id="menu-menu-bar"
          className={classNames(
            'navbar-menu',
            !collapsed ? 'is-active' : undefined
          )}
        >
          <div className="navbar-start">
            <div className="navbar-brand">
              <Logo textOnly white />
            </div>
            <Link
              to="/"
              className="navbar-item"
              onClick={() => setCollapsed(true)}
            >
              Home
            </Link>
            {/* <Link to="/dokumentation" onClick={() => setCollapsed(false)} className="navbar-item">
              Dokumentation
            </Link> */}
            <Link
              to="/download"
              onClick={() => {
                console.log('hi');
                setCollapsed(true);
              }}
              className="navbar-item"
            >
              Download
            </Link>
            <Link
              to="/register"
              onClick={() => setCollapsed(true)}
              className="cta-link navbar-item"
            >
              Jetzt kostenlos testen!
            </Link>
          </div>

          <div className="navbar-end">
            {user && (
              <>
                <Link
                  to="/mein-fakeos"
                  onClick={() => setCollapsed(true)}
                  className="navbar-item"
                >
                  Mein fakeOS
                </Link>
                <Link
                  to="/logout"
                  onClick={() => setCollapsed(true)}
                  className="navbar-item"
                >
                  Logout
                </Link>
              </>
            )}
            {!user && (
              <>
                <Link
                  to="/login"
                  onClick={() => setCollapsed(true)}
                  className="navbar-item"
                >
                  Mein fakeOS
                </Link>
                <Link
                  to="/register"
                  onClick={() => setCollapsed(true)}
                  className="navbar-item"
                >
                  Registrieren
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};
