import { Link } from 'gatsby';
import React from 'react';
import { useAuth } from '../auth';
import Layout from '../components/layout';
import { Navigation } from '../components/navigation';

export default () => {
  const { user, loading } = useAuth();
  return (
    <Layout>
      <Navigation fixed />
      {user && (
        <div className="section">
          <div className="container is-max-desktop">
            <h3 className="subtitle">Mein</h3>
            <h2 className="title">fakeOS</h2>

            <p>Hallo, {user.firstName}!</p>
            <p>
              Du kannst die aktuelle fakeOS Version für dein Gerät{' '}
              <Link to="/download">hier</Link> herunterladen und installieren.
            </p>
          </div>
        </div>
      )}
    </Layout>
  );
};
